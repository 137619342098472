import moment from "moment";

export const _dayMap = { 0: "6", 1: "0", 2: "1", 3: "2", 4: "3", 5: "4", 6: "5" }

/**
        * @name getPercentageFromNumberValues
        * @description returns array of percentages based on passed aaray of values
        * @param {Object} numArray
        * 
        * @returns {Object} percentageArray
*/
export const getPercentageFromNumberValues = (numArray) => {
    if (!numArray || !numArray.length) return [];
    const percentageArray = [];
    const initialValue = 0;
    const total = numArray.reduce((accumulator, currentValue) =>
        parseInt(accumulator) + parseInt(currentValue), initialValue);
    numArray.forEach(num => {
        percentageArray.push(Math.round((num / total) * 100));
    })
    return percentageArray;
}


export const getPercentage = (total_qty, current_qty) => {
    if (!total_qty) return 0;
    return Math.round((current_qty / total_qty) * 100)
}


/**
        * @name getFormattedAddress
        * @description returns address string according to regional settings
        * @param {Object} address
        * @param {Object} dateTimeRules
        * 
        * @returns {String} address_string
*/
export const getFormattedAddress = (address, address_format) => {
    address.post_number = address.post_number != null ? address.post_number : false;
    address.post_place = address.post_place != null && address.post_place.trim() !== "" ? address.post_place : false;
    address.address = address.address != null && address.address.trim() !== "" ? address.address : false;
    let formated_address = "";
    if (address_format === 'address,pincode,city') {
        formated_address = `${address.address ? address.address : ""}, ${address.post_number ? address.post_number : ""} ${address.post_place ? address.post_place : ""}`
    }
    else {
        formated_address = `${address.address ? address.address : ""}, ${address.post_place ? address.post_place : ""} ${address.post_number ? address.post_number : ""}`
    }
    return formated_address;
}


/**     
        * @name formatBankAccountNumber
        * @description Formats a bank account number by removing non-digit characters and applying a desired format.
        *
        * @param {string} accountNumber - The bank account number to be formatted.
        * @return {string} formattedNumber - The formatted bank account number.
 */
export const formatBankAccountNumber = (accountNumber) => {
    // Remove any non-digit characters from the account number
    const cleanedNumber = accountNumber.replace(/\D/g, '');

    // Apply the desired format using regular expressions
    const formattedNumber = cleanedNumber.replace(/(\d{4})(\d{2})(\d{1,5})(\d*)/, '$1-$2-$3$4');

    return formattedNumber;
}

/**
        * @name compareObjects
        * @description method used for sorting array of objects using the target property
        * @param {Object} a
        * @param {Object} b
        * @param {String} targetProperty
        * @param {Boolean} desc
        * 
        * @returns {Integer} difference
*/
export const compareObjects = (a, b, targetProperty, desc = false) => {
    if (!targetProperty) return 0;
    const targetPropertyDataType = typeof a[targetProperty];
    const _a = a[targetProperty] ? a[targetProperty] : targetPropertyDataType === 'number' ? 0 : '';
    const _b = b[targetProperty] ? b[targetProperty] : targetPropertyDataType === 'number' ? 0 : '';
    if (targetPropertyDataType === 'number') {
        return desc ? _b - _a : _a - _b;
    }
    else {
        return desc ? _b.localeCompare(_a) : _a.localeCompare(_b);
    }
}


/**
        * @name isWeekend
        * @description method used for checking if day is weekend
        * @param {Integer} day
        * @param {String} clientDefultHolidays
        * 
        * @returns {Boolean} isWeekend
*/
export const isWeekend = (day, clientDefultHolidays) => {
    if (!clientDefultHolidays) return false;
    if (day === 0) {
        day = 6;
    } else {
        day -= 1;
    }
    return clientDefultHolidays.indexOf(day.toString()) > -1;
}


/**
        * @name checkIfTimelogHasWeekendInExtrapayments
        * @description method used for checking if day is weekend as per rules extrapayment rules
        * @param {string} forDate
        * @param {string} employeeExtraPayments
        * @param {Object} extraPaymentRules
        * 
        * @returns {Boolean} isWeekend
*/
export const checkIfCurrentDaysIsWeekendAsPerExtraPaymentSettings = (forDate, employeeExtraPayments = "", extraPaymentRules = []) => {
    if (!forDate || !moment(forDate).isValid()) return false;
    const weekendRule = extraPaymentRules.find(ele => ele.code === 'weekends' && ele.isActive);
    if (!weekendRule) return false;
    if (!employeeExtraPayments || !employeeExtraPayments.includes('weekends')) return false;
    const _forDateDay = _dayMap[moment(forDate).day()];
    return weekendRule.daysOfWeek.includes(_forDateDay);
}


/**
        * @name calculateBusinessDays
        * @description method used for calculate the business days
        * @param {string} from
        * @param {string} to
        * @param {string} defaultHolidaysForVacation
        * @param {boolean} accountForHolidays
        * 
        * @returns {Integer} workingDays
*/
export const calculateBusinessDays = (from, to, defaultHolidaysForVacation, accountForHolidays) => {
    let d1 = moment(from);
    let d2 = moment(to);
    const days = d2.diff(d1, "days") + 1;
    let newDay = d1.toDate(), workingDays = 0;

    for (let i = 0; i < days; i++) {
        const _forDateDay = _dayMap[moment(newDay).day()];
        if (!accountForHolidays || !defaultHolidaysForVacation?.includes(_forDateDay)) {
            workingDays++;
        }
        newDay = d1.add(1, "days").toDate();
    }
    return workingDays;
}

/**
        * @name getDaysFromDaysIndex
        * @description method used get Days from Days Index
        * @param {String} clientDefultHolidays
        * 
        * @returns {String} Days String
*/
export const getDaysFromDaysIndex = (clientDefultHolidays) => {
    const arr = clientDefultHolidays.split(',')
    getMomemntLocale();
    const _arr = arr.map(ele => {
        if (ele === 6)
            return 0;
        else
            return ele = parseInt(ele) + 1;
    })
    const days = _arr.map(ele => moment().day(ele).format('ddd'))
    return days.join(',')
}

/**
        * @name getMomemntLocale
        * @description method used get moment locale
        * 
        * @returns {Object} get moment locale
*/
export const getMomemntLocale = () => {
    const lang = window.sessionStorage.getItem('locale');
    return moment.locale(lang === 'no' ? 'nb' : lang);
}

export const compareFuncInt = (a, b) => a - b;

/**
        * @name getUserExtraPayments
        * @description method to get all the assigned extra payments in user profile
        * from control panel
        * @param {Object} user
        * @param {Object} extraPaymentRules
        * @param {Object} forDate
        * @param {Object} timelogShift
        * @param {boolean} employeeAssignedForEmergencyShift
        * @param {number} task_id
        *
        * @returns {Object} extraPaymentS
*/
export const getUserExtraPayments = (user, extraPaymentRules, forDate, timelogShift, employeeAssignedForEmergencyShift, task_id) => {
    if (!user || !extraPaymentRules || !forDate) return [];
    extraPaymentRules = extraPaymentRules.filter(ele => ele.code !== 'shift_allowance')
        .map(ele => {
            return {
                ...ele,
                isEditable: ele.fullDay && ele.code !== 'weekends',
                helpText: !ele.fullDay || ele.code === 'weekends' ? 'These will be automatically filled by the system' : 'Please fill holiday hours manually, if you are working on holiday',
                hasConfigIssue: true,
                configIssueText: ""
            }
        });

    const _extraPaymentsInUserProfile = user.extra_payments?.split(',') || [];
    _extraPaymentsInUserProfile.push('emergency_shifts');
    const _forDateDay = _dayMap[moment(forDate).day()];
    let __extraPaymentRules = [];

    if (timelogShift && timelogShift.user_group > 0) {
        __extraPaymentRules = extraPaymentRules && extraPaymentRules.length ?
            extraPaymentRules.filter(ele => ele.isActive
                && ele.daysOfWeek.includes(_forDateDay)
                && (ele.userGroups === 'all' || ele.userGroups?.split(',').includes(`${timelogShift.user_group}`))
                && (ele.employmentTypes === 'all' || ele.employmentTypes === user.payment_mode)
                && (!ele.task || ele.task === task_id)
            ).map(ele => {
                return { ...ele, hasConfigIssue: false, configIssueText: "" };
            })
            : [];
    }

    if (_extraPaymentsInUserProfile.length && extraPaymentRules) {
        extraPaymentRules.forEach(ele => {
            if (ele.isActive
                && _extraPaymentsInUserProfile.includes(ele.code)
                && ele.daysOfWeek.includes(_forDateDay)
                && (ele.employmentTypes === 'all' || ele.employmentTypes === user.payment_mode)
                && !__extraPaymentRules.some(_ele => _ele.code === ele.code)
                && (!ele.task || ele.task === task_id)) {
                if (ele.code === 'emergency_shifts' && !employeeAssignedForEmergencyShift)
                    __extraPaymentRules.push({ ...ele, isEditable: false, hasConfigIssue: true, configIssueText: "This Additional Payment is not assigned for the this employee" });
                else
                    __extraPaymentRules.push({ ...ele, hasConfigIssue: false, configIssueText: "" });
            } else {
                if (!ele.isActive) {
                    __extraPaymentRules.push({ ...ele, isEditable: false, hasConfigIssue: true, configIssueText: "This Additional Payment is not active in control panel" });
                }
                else if (!_extraPaymentsInUserProfile.includes(ele.code)) {
                    __extraPaymentRules.push({ ...ele, isEditable: false, hasConfigIssue: true, configIssueText: "This Additional Payment is not active in employee profile" });
                }
                else if (ele.task && ele.task !== task_id) {
                    __extraPaymentRules.push({ ...ele, isEditable: false, hasConfigIssue: true, configIssueText: "This Additional Payment is not available for this task" });
                }
                else {
                    __extraPaymentRules.push({ ...ele, isEditable: false, hasConfigIssue: true, configIssueText: "The current timesheet is not matching all the Additional Payment settings" });
                }
            }
        });
    }
    if (__extraPaymentRules.some(ele => ele.code === 'weekends' && !ele.hasConfigIssue)) {
        __extraPaymentRules = __extraPaymentRules.map(ele => {
            if (ele.code !== 'weekends' && !ele.hasConfigIssue) {
                ele.isEditable = false;
                ele.hasConfigIssue = true;
                ele.configIssueText = "Timesheet will be considered under weekends extra payments";
            }
            return ele;
        })
    }
    __extraPaymentRules = __extraPaymentRules.sort((a, b) => (+b.isEditable) - (+a.isEditable));
    return __extraPaymentRules;
}

/**
        * @name getGroupName
        * @description method used get all members with groups
        * 
        * @returns {Map} get Members
*/
export const getGroupName = (clientGroups) => {
    const getClientGroupMap = new Map()
    clientGroups.forEach(ele => {
        getClientGroupMap.set(ele.code, ele)
    })
    return getClientGroupMap
}

/**
        * @name translateLabels
        * @description method used translate labels inside options array.
        * @param {Array} options
        * @param {Function} translate
        * 
        * @returns {Array} translated options
*/
export const translateLabels = (options, translate) => {
    options.forEach(ele => {
        ele.title = translate(ele.key);
    })
}

/**
        * @name getDateAsPerServer
        * @description method to get the date with server timezone difference
        * @param {Number} timezoneGMT
        * @param {Date} forDate
        * 
        * @returns {import("moment").Moment} 
*/
export const getDateAsPerServer = (timezoneGMT, forDate = new Date()) => {
    return moment(forDate).add(timezoneGMT, 'hours')
}

/**
        * @name getDistanceZone
        * @description method used for checks and returns distance object.
        * @param {Number} logDistance
        * @param {Object} clientKilometersRules
        * 
        * @returns {Object} distanceObject
*/
export const getDistanceZone = (logDistance, clientKilometersRules) => {
    const kilometersSort = clientKilometersRules.map(ele => {
        ele.max_km = parseFloat(ele.max_km)
        return ele
    }).sort((a, b) => a.max_km - b.max_km);
    for (let i = 0; i < kilometersSort.length; i++) {
        if (kilometersSort[i].max_km >= logDistance) return kilometersSort[i];
    }
    return kilometersSort[kilometersSort.length - 1];
}
