import { observable, action, makeObservable } from 'mobx';

// import getBase64 from '~/utils/getBase64';
import agent from '../agent';

class DashboardStore {

    currentData = [];
    todayAtWork = [];
    nextShifts = [];
    loading = true;
    tableLoading = false;

    constructor() {
        makeObservable(this, {
            currentData: observable,
            todayAtWork: observable,
            nextShifts: observable,
            loading: observable,
            tableLoading: observable,
            load: action,
            getDashboardSchedules: action,
            handleShowLess: action,
            resetLoader: action,
            loadUnloggedUsersList: action
        });
    }

    load(params) {
        this.loading = true;
        return agent.Dashboard.get(params)
            .then(
                action(list => {
                    this.currentData = list;
                    this.todayAtWork = (list && list.todayAtWork && list.todayAtWork.shifts) || [];
                    this.loading = false;
                    return list;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    }

    getDashboardSchedules(params) {
        this.tableLoading = true;
        return agent.Dashboard.getSchedules(params)
            .then(
                action(list => {
                    const shifts = list.shifts || [];
                    this.nextShifts = shifts;
                    this.todayAtWork.push(...shifts);
                    this.tableLoading = false;
                    return shifts;
                })
            )
            .catch(
                action(err => {
                    throw err;
                })
            );
    }

    handleShowLess() {
        this.todayAtWork = this.todayAtWork.slice(0, 6);
    }

    resetLoader() {
        this.loading = true;
    }
   
    loadUnloggedUsersList(params) {
        this.loading = true;
        return agent.Dashboard.getUnloggedUsers(params)
            .then(
                action(list => {                                      
                    this.loading = false;
                    return list;
                })
            )
            .catch(
                action(err => {
                    this.loading = false;
                    throw err;
                })
            );
    } 
}

const _DashboardStore = new DashboardStore();
export default _DashboardStore
