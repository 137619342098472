import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';

//components//
import applicationRouter from '~/hoc/applicationRouter'
import withLocalization from '~/hoc/withLocalization';
import Checkbox from '../../../../components/CustomCheckbox/CustomCheckbox.jsx';

//elements//
import { NumberInput, Input } from '../../../../elements/Input';
import Select from '../../../../elements/Select.js';
import SwitchWidget from '../SwitchWidget';


//constants//
const approvalTimelogsOptions = [{ value: 'auto', label: 'Automatic', title: 'Automatic' },
{ value: 'auto-no-overtime', label: 'Automatic if no overtimes', title: 'Automatic if no overtimes' },
{ value: 'manual', label: 'manual', title: 'manual' }]

const approvalVacationsOptions = [{ value: 'auto', label: 'Automatic', title: 'Automatic' },
{ value: 'manual', label: 'Manual', title: 'manual' }]

const approvalAbsentsOptions = [{ value: 'auto', label: 'Automatic', title: 'Automatic' },
{ value: 'manual', label: 'manual', title: 'manual' }]


class General extends Component {
    constructor(props) {
        super(props);
        this.state = {
            generalRules: {
                selfClarificationPeriod: 0,
                defaultHolidays: "",
                allowManualTimelogChange: false,
                allow_overtime_limit: false,
                displayCommentAfterFinish: false,
                disableDeleteTimelogWhenApproved: false,
                allowQucikChecklist: false,
                quickCheckListText: 'Did you get any injury at site today?',
                approvalAbsents: 'manual',
                approvalTimelogs: 'manual',
                approvalVacations: 'manual',
                maxHouresToEditWorkingHours: 48,
                workinghourUserphoto: false,
                workinghourGPSObligatory: false,
                showAbsenceAndVacationInMobile:false,
                signatureModalAfterWorklog: false,
                messageSetting: "read_write", // members default setting
                hideBreakButton: false,
                hideSensitiveDetailsFromPM: false,
            },
            defaultDays: [{ day: 'Mon', checked: false }, { day: 'Tue', checked: false },
            { day: 'Wed', checked: false }, { day: 'Thu', checked: false },
            { day: 'Fri', checked: false }, { day: 'Sat', checked: false },
            { day: 'Sun', checked: false }],
        }
        // this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSwitch = this.handleSwitch.bind(this);
        this.handleCheck = this.handleCheck.bind(this);
        this.loadData = this.loadData.bind(this);
        this.updatedWeekDays = this.updatedWeekDays.bind(this);
        this.parseWeekDays = this.parseWeekDays.bind(this);
        this.translateLabels = this.translateLabels.bind(this);
    }

    loadData = () => {
        const { currentEntity } = this.props.clientStore;
        const { generalRules } = this.state;
        if (currentEntity.client.data && currentEntity.client.data.basicRules) {
            const basicRules = Object.assign({}, currentEntity.client.data.basicRules);
            generalRules.selfClarificationPeriod = basicRules.selfClarificationPeriod ? basicRules.selfClarificationPeriod : 16;
            generalRules.defaultHolidays = basicRules.defaultHolidays ? basicRules.defaultHolidays : "";
            generalRules.allowManualTimelogChange = basicRules.allowManualTimelogChange ? basicRules.allowManualTimelogChange : false;
            generalRules.allow_overtime_limit = basicRules.allow_overtime_limit ? basicRules.allow_overtime_limit : false;
            generalRules.disableDeleteTimelogWhenApproved = basicRules.disableDeleteTimelogWhenApproved ? basicRules.disableDeleteTimelogWhenApproved : false;
            generalRules.displayCommentAfterFinish = basicRules.displayCommentAfterFinish ? basicRules.displayCommentAfterFinish : false;
            generalRules.allowQucikChecklist = basicRules.allowQucikChecklist ? basicRules.allowQucikChecklist : false;
            generalRules.quickCheckListText = basicRules.quickCheckListText ? basicRules.quickCheckListText : 'Did you get any injury at site today?';
            generalRules.approvalAbsents = basicRules.approvalAbsents ? basicRules.approvalAbsents : 'manual';
            generalRules.approvalTimelogs = basicRules.approvalTimelogs ? basicRules.approvalTimelogs : 'manual';
            generalRules.approvalVacations = basicRules.approvalVacations ? basicRules.approvalVacations : 'manual';
            generalRules.maxHouresToEditWorkingHours = basicRules.maxHouresToEditWorkingHours ? basicRules.maxHouresToEditWorkingHours : 48;
            generalRules.workinghourUserphoto = basicRules.workinghourUserphoto ? basicRules.workinghourUserphoto : false;
            generalRules.workinghourGPSObligatory = basicRules.workinghourGPSObligatory ? basicRules.workinghourGPSObligatory : false;
            generalRules.signatureModalAfterWorklog = basicRules.signatureModalAfterWorklog ? basicRules.signatureModalAfterWorklog : false;
            generalRules.showAbsenceAndVacationInMobile = basicRules.showAbsenceAndVacationInMobile ? basicRules.showAbsenceAndVacationInMobile : false;
            generalRules.hideBreakButton = basicRules.hideBreakButton ? basicRules.hideBreakButton : false;
            generalRules.messageSetting = basicRules.messageSetting ? basicRules.messageSetting : "read_write";
            generalRules.hideSensitiveDetailsFromPM = basicRules.hideSensitiveDetailsFromPM ? basicRules.hideSensitiveDetailsFromPM : false;
            this.setState({ generalRules },
                () => { this.updatedWeekDays() })
        }
        else {
            this.updatedWeekDays();
        }
    }

    componentDidMount() {
        this.loadData();
    }

    translateLabels = () => {
        const { t } = this.props;
        approvalTimelogsOptions.map(item => {
            item.label = t(item.title)
        })
        approvalVacationsOptions.map(item => {
            item.label = t(item.title)
        })
        approvalAbsentsOptions.map(item => {
            item.label = t(item.title)
        })
    }

    handleTextChange = (name, value) => {
        const { generalRules } = this.state;
        generalRules[name] = value;
        this.props.handleUserDataChange(name, value);
        this.setState({ generalRules });
    }

    handleSwitch = (name, evt) => {
        const { generalRules } = this.state;
        generalRules[name] = evt;
        this.props.handleUserDataChange(name, evt);
        this.setState({ generalRules })
    }

    handleCheck = (index, value) => {
        const { generalRules } = this.state;
        let idsArray = [];
        idsArray = this.parseWeekDays();
        //based on value adds or removes from idsArray//
        if (value == true) {
            idsArray.push(index);
        } else {
            if (generalRules.defaultHolidays.includes(index)) {
                idsArray = idsArray.filter(function (item) {
                    return parseInt(item) !== index
                })
            }
        }
        generalRules.defaultHolidays = idsArray.toString();
        this.props.handleUserDataChange('defaultHolidays', idsArray.toString());
        this.setState({ generalRules }, () => {
            this.updatedWeekDays();
        });
    }

    //maps and parse days from weekdays//
    parseWeekDays = () => {
        const { generalRules } = this.state;
        let idsArray = []
        if (generalRules.defaultHolidays != null && generalRules.defaultHolidays != "") {
            idsArray = generalRules.defaultHolidays.split(",").map(function (item) {
                return parseInt(item, 10);
            });
        }
        return idsArray;
    }

    updatedWeekDays = () => {
        let idsArray = this.parseWeekDays();
        let updatedDays = this.state.defaultDays;
        updatedDays = this.state.defaultDays.map((item, index) => {
            if (idsArray.includes(index)) {
                item.checked = true;
                return item;
            } else {
                item.checked = false;
                return item;
            }
        })
        this.setState({ defaultDays: updatedDays });
    }


    render() {
        const { generalRules, defaultDays } = this.state;
        const { t } = this.props;
        this.translateLabels();
        return (
            <div>
                <Row className='mt-4'>
                    <Col sm={12}>
                        <label className="control-label">{t('Default holidays')}</label>
                        <div className="row-flex" style={{ overflowX: 'auto' }}>
                            {defaultDays.map((item, index) => (
                                <div className='ml-10' key={uuid()}>
                                    <Checkbox
                                        inline
                                        key={`${index}`}
                                        isChecked={item.checked}
                                        onChange={evt => this.handleCheck(index, evt)}
                                        label={t(item.day)}
                                        disabled={true}
                                    />
                                </div>
                            ))}
                        </div>
                        <span className='fw-bold font-size-12'>
                            {t("Note")}:&nbsp;{t("We have moved default holiday settings to additional payments settings, please update under weekends in additional payments")}
                        </span>
                    </Col>
                </Row>

                <Row className='mt-4'>
                    <Col sm={6}>
                        <Input
                            label={`${t('Quick query text')}`}
                            value={generalRules.quickCheckListText}
                            onChange={evt => {
                                this.handleTextChange('quickCheckListText', evt.target.value);
                            }}
                        />
                    </Col>
                </Row>

                <Row className='mt-4'>
                    <Col className="mt-1" sm={1}>
                        <SwitchWidget
                            value={generalRules.allowQucikChecklist}
                            onChange={checked => this.handleSwitch('allowQucikChecklist', checked)} />
                    </Col>
                    <Col sm={10}>
                        <label className="control-label">{t('Show quick query in mobile app')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t(`If this option is enabled in the control panel, it will be available in the mobile app. When the user finishes their work, they can inform if any mishap occurred, and all project managers will be notified immediately`
                                    )}.
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col className="mt-1" sm={1}>
                        <SwitchWidget
                            value={generalRules.allowManualTimelogChange}
                            onChange={checked => this.handleSwitch('allowManualTimelogChange', checked)} />
                    </Col>
                    <Col sm={10}>
                        <label className="control-label">{t('Allow change timelog without switching to manual mode')}</label>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("A project manager or administrator can modify a work log without it being shown as manually changed in the time log")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="mt-1" sm={1}>
                        <SwitchWidget
                            value={generalRules.allow_overtime_limit}
                            onChange={checked => this.handleSwitch('allow_overtime_limit', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Allow registering more than 9 hours without requiring overtime confirmation')}.</label>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("This function will allow users to register up to 9 hours of work without requiring overtime payment")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="mt-1" sm={1}>
                        <SwitchWidget
                            value={generalRules.displayCommentAfterFinish}
                            onChange={checked => this.handleSwitch('displayCommentAfterFinish', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Allow to display comment dialog after finish work')}</label>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("When users click on the finish button in the app at the end of the day or when a task is completed, this function allows them to enter a comment on what was done during the task. A text field will pop up in the app where the user can enter the text")}.
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="mt-1" sm={1}>
                        <SwitchWidget
                            value={generalRules.signatureModalAfterWorklog}
                            onChange={checked => this.handleSwitch('signatureModalAfterWorklog', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Show Signature Modal after finishing work in mobile app')}</label>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("At the end of the workday, users will be prompted to sign and confirm the work hours they have registered")}.
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="mt-1" sm={1}>
                        <SwitchWidget
                            value={generalRules.workinghourUserphoto}
                            onChange={checked => this.handleSwitch('workinghourUserphoto', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Allow Capture live image of user when start working hours')}</label>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("Allow Capture live image of user when start working hours")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="mt-1" sm={1}>
                        <SwitchWidget
                            value={generalRules.workinghourGPSObligatory}
                            onChange={checked => this.handleSwitch('workinghourGPSObligatory', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Make GPS obligatory for start/end time in mobile app, without GPS on workslogs cant be registered')}</label>
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t(`If GPS is turned off on the mobile device, users cannot click on the start/end button. To use this function, make sure to register the location under address projects and tasks`
                                    )}.
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.showAbsenceAndVacationInMobile}
                            onChange={checked => this.handleSwitch('showAbsenceAndVacationInMobile', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Display Sick leave And Vacation button in Mobile app')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("This rule will display members to access vacations and absence from mobile app.")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.hideBreakButton}
                            onChange={checked => this.handleSwitch('hideBreakButton', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Hide break button in working hours')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("This rule will not show break button in working hours both in mobile app and web. System will calculate break time automatically according to selected rules of the break.")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                    <Row className="mt-2">
                        <Col sm={1}>
                            <SwitchWidget
                                value={generalRules.hideSensitiveDetailsFromPM}
                                onChange={checked => this.handleSwitch('hideSensitiveDetailsFromPM', checked)} />
                        </Col>
                        <Col sm={11}>
                            <label className="control-label">{t("Hide Member's Sensitive Details from PM")}</label>
                            <OverlayTrigger
                                placement="right"
                                overlay={
                                    <Tooltip id="tooltip-top">
                                        {t("By Enabling this feature PM will not be able to view and edit the sensitive details of Member like SSN, Bank details, Payment details, etc")}
                                    </Tooltip>
                                }
                            >
                                <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                </Row>
                  <Row className="mt-2">
                    <Col sm={1}>
                        <SwitchWidget
                            value={generalRules.disableDeleteTimelogWhenApproved}
                            onChange={checked => this.handleSwitch('disableDeleteTimelogWhenApproved', checked)} />
                    </Col>
                    <Col sm={11}>
                        <label className="control-label">{t('Disable delete timelog when approved')}</label>
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id="tooltip-top">
                                    {t("This rule will not show delete button in timelog when status is approved")}
                                </Tooltip>
                            }
                        >
                            <i className="fa fa-info-circle text-muted" style={{ opacity: '0.5' }} aria-hidden="true"></i>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col sm={4} md={4}>
                        <label className="control-label">{t('Time log approval')}</label>
                        <Select
                            menuPlacement={"top"}
                            options={approvalTimelogsOptions}
                            value={approvalTimelogsOptions.find(item => item.value === generalRules.approvalTimelogs)}
                            onChange={evt => this.handleSwitch('approvalTimelogs', evt.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col xs={12} sm={4}>
                        <label className="control-label">{t('Vacations approval')}</label>
                        <Select
                            menuPlacement={"top"}
                            options={approvalVacationsOptions}
                            value={approvalVacationsOptions.find(item => item.value === generalRules.approvalVacations)}
                            onChange={evt => this.handleSwitch('approvalVacations', evt.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col xs={12} sm={4}>
                        <label className="control-label">{t('Absents approval')}</label>
                        <Select
                            menuPlacement={"top"}
                            options={approvalAbsentsOptions}
                            value={approvalAbsentsOptions.find(item => item.value === generalRules.approvalAbsents)}
                            onChange={evt => this.handleSwitch('approvalAbsents', evt.value)}
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
export default inject('clientStore')(applicationRouter(withLocalization(observer(General))));